import {useForm} from "react-hook-form";
import CardTable from "../components/card/CardTable";
import {ColumnInterface} from "../components/table/typings";
import {useQueries} from "../hooks/useQueries";
import {APP_API_URL} from "../config/api";
import useHeaderFunctionPage from "../hooks/useHeaderFunctionPage";
import moment from "moment";
import Card from "../components/card/Card";
import {Form} from "../components/form/Form";
import {useRef} from "react";
import {Select2} from "../components/form/Select";
import SimpleButton from "../components/button/SimpleButton";
import {BiSearchAlt2} from "react-icons/bi";
import {InputDate} from "../components/form/Input";

export default function ClientPage() {
    /**
     * Hooks
     * */
    const {baseApiUrl, title, pagination, setPagination} =
        useHeaderFunctionPage({baseApiUrl: "client", title: "Clients"});
    const town = useRef(null);
    const gender = useRef(null);
    const answer = useRef(null);
    const fromDate = useRef(moment().subtract(7, "day").format("YYYY-MM-DD"));
    const toDate = useRef(moment().format("YYYY-MM-DD"));
    const queryKey = [
        `${baseApiUrl}s`,
        pagination.page,
        pagination.perPage,
        town.current,
        gender.current,
        answer.current,
        fromDate.current,
        toDate.current,
    ];

    /**
     * Form
     */
    const {
        register,
        control,
        handleSubmit,
        formState: {errors},
    } = useForm();

    /**
     * Query
     */
    const {data: datas, isLoading} = useQueries(
        APP_API_URL +
        `${baseApiUrl}s?page=${pagination.page}&perPage=${pagination.perPage}`,
        queryKey
    );


    /**
     * Function
     */
    /**
     * Columns Table
     */
    const columns: ColumnInterface[] = [
        {
            title: "Numéro",
            key: "number",
            dataIndex: "number",
        },{
            title: "Numéro court",
            key: "sender_address",
            dataIndex: "sender_address",
        },{
            title: "Mot clé",
            key: "keyword",
            dataIndex: "keyword",
        },{
            title: "Message",
            key: "message",
            dataIndex: "message",
        },
        {
            title: "Date",
            key: "created_at",
            render: (element: any) => (
                <>{moment(element.created_at).format("YYYY-MM-DD HH:mm:ss")}</>
            ),
        },

    ];

    /**
     * UI
     */

    return (
        <>
            <CardTable
                extras={[]}
                columns={columns}
                title={title}
                loading={isLoading}
                datas={datas?.data}
                pagination={pagination}
                setPagination={setPagination}
                tableClassName="text-center"
            />
        </>
    )
        ;
}
