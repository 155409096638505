import { UserRoles } from "./global"
import {MdContactPhone} from "react-icons/md";
import {BiHourglass} from "react-icons/bi";

export interface MenuInterface {
    icon: React.FC;
    title: string;
    link: string;
    pathname: string;
    roles?: UserRoles[];
    subMenus?: MenuInterface[];
  }
  
export const MENUS: MenuInterface[] = [
    {
        icon: MdContactPhone,
        title: "Clients",
        link: "/clients",
        pathname: "clients"
    },
    /*{
        icon: MdPayments,
        title: "Paiements",
        link: "/payments",
        pathname: "payments"
    },*/
]
